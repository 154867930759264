import Const from '../constants'
import axiosApiInstance from '../interceptors'

class CreditCardVerificationService {
  getCreditCard() {
    return axiosApiInstance.get(Const.api_url + 'member/card-info')
  }

  changeCreditCard(paygentTokenStr: any,
    finger_print: any,
    card_number: any,
    expire_year: any,
    expire_month: any,
    firstName: any,
    lastName: any) {
    return axiosApiInstance.post(Const.api_url + 'member/card-info-change', {
      card_token: paygentTokenStr,
      finger_print: finger_print,
      card_number: card_number,
      expire_month: expire_month,
      expire_year: expire_year,
      user_first_name: firstName,
      user_last_name: lastName
    })
  }

  deleteCreditCard() {
    return axiosApiInstance.post(Const.api_url + 'member/delete-card')
  }
}

export default new CreditCardVerificationService()
