





















import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Cart from '@/components/Cart.vue'
import SearchHeader from '@/components/SearchHeader.vue'
import DeliveryService from '@/components/DeliveryService.vue'
import CategoryMenu from '@/components/CategoryMenu.vue'
import SidebarPc from '@/components/SidebarPc.vue'

@Component({
  components: {
    Header,
    CategoryMenu,
    Footer,
    Cart,
    SidebarPc,
    SearchHeader,
    DeliveryService
  }
})
export default class LayoutStoreFirst extends Vue {}
