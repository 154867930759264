







































































































































import { Component, Vue } from 'vue-property-decorator'
import LayoutSideBar from '@/layouts/LayoutSideBar.vue'
import CreditCardVerificationService from '@/services/CreditCardVerificationService'

@Component({
  components: {
    LayoutSideBar
  }
})
export default class CreditCardVerification extends Vue {
  public msg: boolean = false
  public msgCannotDeleteCreditCard: boolean = false
  public card_brand: string = 'カード情報がありません。'
  public card_number: string = 'カード情報がありません。'
  public card_valid_term: string = 'カード情報がありません。'
  public cardholder_name: string = 'カード情報がありません。'

  created() {
    CreditCardVerificationService.getCreditCard().then(
      (response: any) => {
        let items = response.data.items.data
        if (items.card_brand !== '') {
          this.card_brand = items.card_brand
        }
        if (items.card_number !== '') {
          this.card_number = items.card_number
        }
        if (items.card_valid_term !== '') {
          this.card_valid_term = items.card_valid_term
        }
        if (items.cardholder_name !== '') {
          this.cardholder_name = items.user_first_name + '　' + items.user_last_name
        }
      },
      (error: any) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
        if (error.response.status === 500) {
          if (
            error.response.data.message[0] === 'CAN_NOT_GET_CARD_INFORMATION'
          ) {
            this.msg = true
          }
        }
      }
    )
  }

  public changeCreditCard() {
    this.$router.push('change-credit-card')
  }

  public confirmDeteleCreditCard() {
    this.$bvModal.show('confirm-delete-credit-card')
  }

  public deleteCreditCard() {
    this.$bvModal.hide('confirm-delete-credit-card')
    CreditCardVerificationService.deleteCreditCard().then(
      (response: any) => {
        if (response) {
          this.$bvModal.show('delete-credit-card-success')
        }
      },
      (error: any) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
        if (error.response.status === 500) {
          if (error.response.data.message[0] === 'CAN_NOT_DELETE_CARD') {
            this.$bvModal.show('cannot-delete-credit-card')
          } else if (
          error.response.data.message[0] ===
          'CANNOT_DELETE_OLD_CARD_INFO'
          ) {
          this.$bvModal.show('cannot-delete-old-card-info')
        }
        }
      }
    )
  }

  public deleteCreditCardSuccess() {
    window.location.reload()
  }
}
